import React from 'react';
import wrap, { IconCommonInterface } from './iconWrap';

const IconEmail: React.FC<IconCommonInterface> = ({
  size
}) => (
  <svg width={size} height={size}  fill="none" viewBox={'0 0 22 24'} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.11111 21C5.13333 21 4.15556 19.95 3.66667 19.5C1.22222 17.4 0.488889 16.65 0 16.2V22.5C0 23.3283 0.547326 24 1.22222 24H11C11.6749 24 12.2222 23.3283 12.2222 22.5V16.2C11.7333 16.65 11 17.4 8.55556 19.5C8.06667 19.95 7.08889 21 6.11111 21ZM11 12H1.22222C0.547326 12 0 12.6717 0 13.5V14.25C0.977778 15.15 0.855555 15.15 4.4 18.3C4.76667 18.6 5.5 19.5 6.11111 19.5C6.72222 19.5 7.45556 18.6 7.82222 18.45C11.3667 15.3 11.2444 15.3 12.2222 14.4V13.5C12.2222 12.6717 11.6749 12 11 12ZM20.7778 7.5H8.55556C7.88066 7.5 7.33333 8.17172 7.33333 9V10.5H11C12.2684 10.5 13.3142 11.6916 13.4334 13.2103L13.4444 13.2V19.5H20.7778C21.4527 19.5 22 18.8283 22 18V9C22 8.17172 21.4527 7.5 20.7778 7.5ZM19.5556 13.5H17.1111V10.5H19.5556V13.5ZM6.11111 9C6.11111 7.34578 7.20767 6 8.55556 6H17.1111V1.5C17.1111 0.671719 16.5638 0 15.8889 0H3.66667C2.99177 0 2.44444 0.671719 2.44444 1.5V10.5H6.11111V9Z"
      fill="currentColor"/>
  </svg>
);

export default wrap(IconEmail);
